import Button, { BtnClose } from './Button';
import ErrorMessage from './Form/ErrorMessage';
import FormGroup from './Form/FormGroup';
import LabelText from './Form/LabelText';
import InputCheckbox from './Input/InputCheckbox';
import InputText from './Input/InputText';
import Modal from './Modal';
import { ProcessList } from './ProcessList';
import { Table } from './Table';
import { Tit } from './Titles';
import { LegacyControllerService } from '@/__generated__/FrontApi';
import InsuranceLogo from '@/assets/img/tmp/legacy/insurance_logo@2x.png';
import LawyerLogo from '@/assets/img/tmp/legacy/lawyer_logo@2x.png';
import LawyerLogoM from '@/assets/img/tmp/legacy/lawyer_logoM@2x.png';
import HeaderObj1 from '@/assets/img/tmp/legacy/legacy_card_obj1@2x.png';
import HeaderObj2 from '@/assets/img/tmp/legacy/legacy_card_obj2@2x.png';
import HeaderObj3 from '@/assets/img/tmp/legacy/legacy_card_obj3@2x.png';
import processImg1 from '@/assets/img/tmp/legacy/process_img1@2x.png';
import processImg2 from '@/assets/img/tmp/legacy/process_img2@2x.png';
import processImg3 from '@/assets/img/tmp/legacy/process_img3@2x.png';
import processImg4 from '@/assets/img/tmp/legacy/process_img4@2x.png';
import processImg5 from '@/assets/img/tmp/legacy/process_img5@2x.png';
import processImg6 from '@/assets/img/tmp/legacy/process_img6@2x.png';
import processImg7 from '@/assets/img/tmp/legacy/process_img7@2x.png';
import TrustLogo from '@/assets/img/tmp/legacy/trust_logo@2x.png';
import Image from '@/components/Image';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { emailRegex, mobileRegex } from '@/helpers/ValidationHelper';
import { usePopupStore } from '@/stores/PopupStore';
import React, { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

export interface ModalProps {
  isOpen?: boolean;
  // 모달 바깥쪽 누를시 닫을수 있는지 여부
  dismissable?: boolean;
  // 닫힘요청시 콜백
  onRequestClose: () => void;
  maxWidth?: string;
}

interface LegacyLayerProps extends ModalProps {
  PopSid: string | number;
  PopTitle: string;
}

const WillProcessData = [
  {
    id: 1,
    img: processImg1,
    desc: [
      {
        dec: `유산기부 취지와 뜻을 가족들에게 알리고 충분히 상의합니다.`,
      },
    ],
  },
  {
    id: 2,
    img: processImg2,
    desc: [
      {
        dec: `유니세프한국위원회 담당자와 상담한 후 함께 기부관련 유언을 작성합니다.`,
      },
    ],
  },
  {
    id: 3,
    img: processImg3,
    desc: [
      {
        dec: `증인 2명과 유언집행자를 선정하고 필요한 서류를 준비합니다.`,
      },
    ],
  },
  {
    id: 4,
    img: processImg4,
    desc: [
      {
        dec: `증인 2명, 유언집행자와 함께 공증인 사무소를 방문하여 유언공증을 합니다.`,
      },
    ],
  },
];

const TrustProcessData = [
  {
    id: 1,
    img: processImg1,
    desc: [
      {
        dec: `기부할 금액을 결정하고, 기부의 취지와 뜻을 가족들에게 알리고 충분히 상의합니다.`,
      },
    ],
  },
  {
    id: 2,
    img: processImg3,
    desc: [
      {
        dec: `신탁사와 상담하여 유언대용신탁의 운용 여부 및 원하시는 조건을 결정합니다.`,
      },
    ],
  },
  {
    id: 3,
    img: processImg2,
    desc: [
      {
        dec: `일반 신탁상품과 동일한 절차에 따라 신탁 계약을 체결하고, 유니세프한국위원회를 사후 수익자로 지정합니다.`,
      },
    ],
  },
  {
    id: 4,
    img: processImg5,
    desc: [
      {
        dec: `지급 사유가 발생하면 신탁 계약에 따라 유니세프한국위원회에 기부됩니다.`,
      },
    ],
  },
];

const InsuranceProcessData = [
  {
    id: 1,
    img: processImg6,
    desc: [
      {
        dec: `본인 명의로 가입한 종신보험 취급 보험사에 수익자 변경 절차를 문의합니다.`,
      },
    ],
  },
  {
    id: 2,
    img: processImg7,
    desc: [
      {
        dec: `유니세프한국위원회 유산기부 담당자에게 연락해서 보험사 방문 일정 등을 확인합니다.`,
      },
    ],
  },
  {
    id: 3,
    img: processImg2,
    desc: [
      {
        dec: `유니세프한국위원회 담당자와 함께 보험사 지점을 방문해 보험의 수익자를 변경합니다.`,
      },
    ],
  },
  {
    id: 4,
    img: processImg5,
    desc: [
      {
        dec: `보험금 지급 사유가 발생하면 유니세프한국위원회에 사망 보험금이 기부됩니다.`,
      },
    ],
  },
];

const PopCcontainer = styled.div`
  padding: 0 32px;

  ${breakpoint(`tablet`)} {
    padding: 0 20px;
  }
`;

const PopupTitle = styled.div`
  border-bottom: 1px solid #1cabe2;
  position: relative;
  padding: 22px 0;

  ${BtnClose} {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);

    &::before,
    &::after {
      background: #1cabe2;
    }
  }
`;

const FormContainer = styled.div`
  max-width: 544px;
  margin: 0 auto;
  padding: 32px 0 96px;

  .btn-wrap {
    margin-top: 80px;

    .btn {
      width: 100%;
      max-width: 100%;
    }
  }
`;

const PopContent = styled.div`
  line-height: 28px;
  .pop-header {
    display: flex;
    align-items: center;
    padding: 32px 0;
    border-bottom: 1px solid #e5e6e8;

    .col-img {
      width: 80px;

      .img-box {
        padding-top: 100%;
      }
    }
    p {
      width: calc(100% - 80px);
      padding-left: 32px;
    }
  }

  .dl-container {
    padding: 48px 24px 96px;
    table {
      margin-top: 0;
    }
    & > dl {
      width: 100%;
      margin-bottom: 48px;

      &:last-child {
        margin-bottom: 0 !important;
      }

      & > dt {
        width: 100%;
        padding-bottom: 16px;

        ${Tit} {
          position: relative;
          padding-left: 20px;

          &::before {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background: #1cabe2;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
          }
        }
      }

      & > dd {
        width: 100%;
        padding-left: 20px;

        ${Table} {
          margin-bottom: 16px;
        }

        .logo-flex {
          p {
            width: calc(100% - 264px);
            padding-right: 24px;
          }
        }

        .process-ol {
          margin-top: 22px;
          dt {
            padding: 0;
            margin-bottom: 16px;
            .img-wrap {
              width: 80px;
            }
          }

          .dec-ul {
            max-width: 132px;
            margin: 0 auto;
          }
        }
      }
    }
  }
  ${breakpoint(`tablet`)} {
    .pop-header {
      flex-wrap: wrap;
      .col-img {
        margin: 0 auto;
      }
      p {
        width: 100%;
        padding-left: 0;
        margin-top: 16px;
      }
    }
    .dl-container {
      padding: 32px 0 72px;

      & > dl {
        margin-bottom: 32px;

        & > dd {
          .logo-flex {
            flex-wrap: wrap;

            p {
              width: 100%;
              padding-right: 0;
              margin-bottom: 24px;
            }
            picture {
              margin: 0 auto;
            }
          }
          .ProcessContainer {
            padding: 0;
          }
        }
      }
    }
  }
`;

const LayerWill: FC = () => (
  <PopContent>
    <header className="pop-header">
      <div className="col-img">
        <div
          className="img-box"
          css={`
            background-image: url(${HeaderObj1});
          `}
        />
      </div>
      <p>
        기부자 (유언자)가 증인 2명이 참석한 가운데 공증인 앞에서 유언 내용을
        말하고, 공증인이 이 같은 유언 내용을 공정증서에 기록하는 방법
      </p>
    </header>
    <div className="dl-container">
      <dl>
        <dt>
          <Tit size="s4">특징</Tit>
        </dt>
        <dd>
          민법이 정한 5가지 유언 방식 중{` `}
          <strong>민법 제 1068조에 따른 공정증서에 의한 유언</strong>이 유언의
          존재 및 내용을 명확히 확정하고, 유언의 효력에 관한 다툼을 방지할 수
          있어서 가장 바람직합니다.
        </dd>
      </dl>
      <dl>
        <dt>
          <Tit size="s4">준비할 서류</Tit>
        </dt>
        <dd>
          <Table>
            <colgroup>
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>유언자 구비서류</th>
                <th>증인 (2명) 구비서류</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  신분증 (주민등록증, 면허증, 여권 중 1가지)
                  <br />
                  주민등록등본
                  <br />
                  가족관계증명서
                  <br />
                  기부할 재산 관련 서류
                </td>
                <td>
                  신분증 (주민등록증, 면허증, 여권 중 1가지)
                  <br />
                  주민등록초본
                </td>
              </tr>
            </tbody>
          </Table>
          <p
            css={`
              margin-top: 16px;
            `}
          >
            ※ 현금, 부동산, 주식, 보험 등 기부하시고자 하는 재산의 종류에 따라
            추가 서류가 필요합니다. 구체적인 내용은 유니세프한국위원회에
            문의하세요.
          </p>
        </dd>
      </dl>
      <dl>
        <dt>
          <Tit size="s4">필요한 사람</Tit>
        </dt>
        <dd>
          - 증인 : 유언의 진실성을 확보하기 위해 공정증서 작성 시에 증인 2인이
          참석해야 합니다. <br />- 유언집행자 : 유언의 효력이 발생한 후에 그
          내용을 실현하는 사람을 정하실 수 있습니다.
        </dd>
      </dl>
      <dl>
        <dt>
          <Tit size="s4">협력 및 지원</Tit>
        </dt>
        <dd>
          <div className="logo-flex flex">
            <p>
              유니세프한국위원회는 대한변호사협회와 유산기부 활성화 및 시스템
              구축을 위해 업무협약을 체결했습니다. 이 협약을 통해 유산기부 시
              유언공증 지원을 위한 <strong>재능기부 변호사단을 운영</strong>
              하고, 유산기부 문화 확산을 위해{` `}
              <strong>법률 상담과 법적 절차 안내를 지원</strong>하고 있습니다.
            </p>
            <Image pcSrc={LawyerLogo} mobileSrc={LawyerLogoM} />
          </div>
        </dd>
      </dl>
      <dl>
        <dt>
          <Tit size="s4">진행 절차</Tit>
        </dt>
        <dd style={{ paddingLeft: 0 }}>
          <ProcessList itemData={WillProcessData} arrow />
        </dd>
      </dl>
    </div>
  </PopContent>
);

const LayerTrust: FC = () => (
  <PopContent>
    <header className="pop-header">
      <div className="col-img">
        <div
          className="img-box"
          css={`
            background-image: url(${HeaderObj2});
          `}
        />
      </div>
      <p>
        유언자가 금융 회사와 자산신탁계약을 맺고 자산관리를 위탁하면서 사망 후
        자산의 전부나 일부를 받게 될 수익자를 유니세프한국위원회로 지정하는 방법
      </p>
    </header>
    <div className="dl-container">
      <dl>
        <dt>
          <Tit size="s4">특징</Tit>
        </dt>
        <dd>
          유언장 작성 및 공증의 복잡한 상속 절차의 생략이 가능하며, 후원자의
          생전 의도에 따른 사후 재산 분배가 가능합니다.
        </dd>
      </dl>
      <dl>
        <dt>
          <Tit size="s4">취급 신탁사</Tit>
        </dt>
        <dd>
          <div className="logo-flex flex">
            <p>
              유니세프한국위원회는 신영증권 및 KB국민은행과 유언대용신탁
              업무협약을 맺고 상호협력하고 있습니다. 신영증권, KB국민은행 외에도
              여러 금융 회사들이 유언대용신탁을 취급합니다.
            </p>
            <Image pcSrc={TrustLogo} mobileSrc={TrustLogo} />
          </div>
        </dd>
      </dl>
      <dl>
        <dt>
          <Tit size="s4">진행 절차</Tit>
        </dt>
        <dd>
          <ProcessList
            itemData={TrustProcessData}
            arrow
            className="ProcessList"
          />
        </dd>
      </dl>
    </div>
  </PopContent>
);

const LayerInsurance: FC = () => (
  <PopContent>
    <header className="pop-header">
      <div className="col-img">
        <div
          className="img-box"
          css={`
            background-image: url(${HeaderObj3});
          `}
        />
      </div>
      <p>
        사망 보험금의 수익자를 유니세프로 지정하는 기부 보험에 가입하거나,
        종신보험 수익자를 유니세프한국위원회로 변경하여 이전과 동일하게 보험료를
        납입하다가 본인 사후에 발생하는 사망 보험금을 기부하는 방법
      </p>
    </header>
    <div className="dl-container">
      <dl>
        <dt>
          <Tit size="s4">특징</Tit>
        </dt>
        <dd>
          피보험자의 동의가 있을 경우, 수익자를 2인 이상으로 지정하실 수
          있습니다. (ex. 가족, 유니세프한국위원회)
        </dd>
      </dl>
      <dl>
        <dt>
          <Tit size="s4">취급 보험사</Tit>
        </dt>
        <dd>
          <div className="logo-flex flex">
            <p>
              유니세프한국위원회와 기부보험 업무협약을 맺고 있는 신한라이프
              생명보험, 메트라이프 생명보험 이외에 여러 보험회사에서 기부보험을
              취급하고 있습니다.
            </p>
            <Image pcSrc={InsuranceLogo} mobileSrc={InsuranceLogo} />
          </div>
        </dd>
      </dl>
      <dl>
        <dt>
          <Tit size="s4">진행 절차</Tit>
        </dt>
        <dd>
          <ProcessList
            itemData={InsuranceProcessData}
            arrow
            className="ProcessList"
          />
        </dd>
      </dl>
    </div>
  </PopContent>
);
export interface FormData {
  userName: string;
  userMobile: string;
  userEmail: string;
  agreeCheck: boolean;
  formType: string;
}
const LayerDownload: FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      userName: ``,
      userMobile: ``,
      userEmail: ``,
      agreeCheck: false,
      formType: `honors`,
    },
  });
  const popupStore = usePopupStore();
  const onSubmit: SubmitHandler<FormData> = useCallback(
    async ({
      userName,
      userMobile,
      userEmail,
      agreeCheck,
      formType,
      ...formData
    }) => {
      try {
        const {
          resultCode,
          resultMessage,
        } = await LegacyControllerService.insertLegacyFileUsingGet({
          ...formData,
          name: userName,
          mobile: userMobile,
          email: userEmail,
          isAgreeYn: agreeCheck ? `Y` : `N`,
          type: `legacy`,
        });
        popupStore.show(resultMessage);
        reset();
      } catch {
        popupStore.show(
          `유산기부 안내자료 신청에 실패했습니다. 다시 시도해주세요.`,
        );
      }
    },
    [popupStore],
  );
  return (
    <PopContent>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormContainer>
          <FormGroup>
            <LabelText require>성명</LabelText>
            <InputText
              placeholder="성명 입력"
              ref={register({
                required: true,
              })}
              name="userName"
            />
            {errors.userName && (
              <ErrorMessage>성명을 입력해주세요.</ErrorMessage>
            )}
          </FormGroup>
          <div className="clear" />
          <FormGroup>
            <LabelText require>휴대폰번호</LabelText>
            <InputText
              type="number"
              placeholder="휴대폰번호 입력 ( &lsquo;-&rsquo; 제외 )"
              ref={register({
                required: {
                  value: true,
                  message: `휴대폰번호를 입력해주세요`,
                },
                pattern: {
                  value: mobileRegex,
                  message: `숫자로만 입력해주세요.`,
                },
                validate: {
                  isValidRange: (value) =>
                    (value.substr(0, 3) === `010` && value.length === 11) ||
                    (value.substr(0, 3) !== `010` && value.length === 10) ||
                    `휴대폰번호 자릿수를 확인해주세요`,
                },
              })}
              name="userMobile"
            />
            {errors.userMobile && (
              <ErrorMessage>휴대폰번호를 입력해주세요.</ErrorMessage>
            )}
          </FormGroup>
          <FormGroup>
            <LabelText require>이메일</LabelText>
            <InputText
              placeholder="이메일주소 입력"
              ref={register({
                required: {
                  value: true,
                  message: `이메일을 입력해주세요.`,
                },
                pattern: {
                  value: emailRegex,
                  message: `올바른 이메일 형식이 아닙니다.`,
                },
              })}
              name="userEmail"
            />
            {errors.userEmail && (
              <ErrorMessage>{errors.userEmail.message}</ErrorMessage>
            )}
          </FormGroup>
          <FormGroup className="full">
            <InputCheckbox
              className="chk-label"
              ref={register({
                required: true,
              })}
              name="agreeCheck"
              label="예, 저는 유산기부 안내 및 법률 자문 등의 서비스를 받기를 원합니다. 그리고 안내를 원치 않을 시, 언제든지 수신 거부 할 수 있음을 잘 알고 있습니다."
            />
            {errors.agreeCheck && (
              <ErrorMessage>약관 동의에 체크해주세요.</ErrorMessage>
            )}
          </FormGroup>
          <div className="btn-wrap">
            <Button className="btn" type="submit">
              유산기부 안내자료 받기
            </Button>
          </div>
        </FormContainer>
      </form>
    </PopContent>
  );
};
const LegacyLayerPopup: FC<LegacyLayerProps> = ({
  onRequestClose,
  ...props
}) => (
  <Modal onRequestClose={onRequestClose} maxWidth="880px" {...props}>
    <PopCcontainer>
      <PopupTitle>
        <h2>
          <Tit
            size="s3"
            color="sky"
            dangerouslySetInnerHTML={{ __html: props.PopTitle }}
          />
        </h2>
        <BtnClose onClick={() => onRequestClose()}>
          <span className="a11y">닫기</span>
        </BtnClose>
      </PopupTitle>
      {props.PopSid === 1 ? <LayerWill /> : ``}
      {props.PopSid === 2 ? <LayerTrust /> : ``}
      {props.PopSid === 3 ? <LayerInsurance /> : ``}
      {props.PopSid === 4 ? <LayerDownload /> : ``}
    </PopCcontainer>
  </Modal>
);

export default LegacyLayerPopup;
